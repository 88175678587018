import dayjs from 'dayjs';
import { from, timer as observableTimer, of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { RunningTimer, TimeEntry, EntryRequest } from '../models/timeentry';
import { StartTimerComponent } from '../shared/dialogs/start-timer/start-timer.component';
import { HubConnection } from '@aspnet/signalr';
import { DayOfWeek } from '../shared/enums';
import { UUID } from 'angular2-uuid';

@Injectable()
export class TimerService {
  private baseUrl = environment.server;
  private projectUrl = `${this.baseUrl}/timer`;
  private exportExcelURL = `${this.baseUrl}/api/File/CreateExcel`;
  private startTimerDiagRef: MatDialogRef<StartTimerComponent>;
  public connection: HubConnection;

  constructor(
    private http: HttpClient,
    private dialogService: MatDialog
  ) {
    // this.initHub();
  }

  public addTimeEntry(timeEntry: TimeEntry): Observable<TimeEntry> {
    const tsObject = JSON.parse(JSON.stringify(timeEntry));
    tsObject.startTime = dayjs(tsObject.startTime).format();
    tsObject.endTime = dayjs(tsObject.endTime).format();
    return this.http.post<TimeEntry>(`${this.baseUrl}/Timer/AddTimeEntry`, JSON.stringify(tsObject));
  }

  public reportStartTimer(entry: TimeEntry): Observable<void> {
    return from(this.connection.send('startTimer', entry));
  }

  public StartTimer(entry: Partial<TimeEntry>): Observable<TimeEntry> {
    return this.http.post<TimeEntry>(`${this.projectUrl}/StartTimer`, JSON.stringify(entry));
  }

  public reportEndTimer(entry: TimeEntry): Observable<void> {
    return from(this.connection.send('endTimer', entry));
  }

  public EndTimer(entry: Partial<TimeEntry>): Observable<TimeEntry> {
    return this.http.post<TimeEntry>(`${this.projectUrl}/EndTimer`, JSON.stringify(entry));
  }

  public UpdateTimeEntry(entries: TimeEntry[]): Observable<string> {
    const tsList = entries.map(entry => {
      return { ...(new TimeEntry(entry)), startTime: dayjs(entry.startTime).format(), endTime: dayjs(entry.endTime).format() };
    });
    return this.http.put(`${this.projectUrl}/UpdateTimeEntries`, JSON.stringify(tsList), { responseType: 'text' });
  }

  public DeleteTimeEntry(entry: TimeEntry): Observable<TimeEntry> {
    return this.http.post<TimeEntry>(`${this.projectUrl}/DeleteTimeEntry`, JSON.stringify(entry));
  }

  public GetRunningTimer(): Observable<RunningTimer> {
    return this.http.get<RunningTimer>(`${this.projectUrl}/GetTimer`);
    // .map(res => res)
  }

  public getWeekRange(date: Date): [string, string] {
    const selectedDate = dayjs(date);
    const startOfWeek = selectedDate.startOf('week');
    // the add method mutates the original object so we have to create a new dayjs
    const endOfWeek = selectedDate.endOf('week');
    return [startOfWeek.toISOString(), endOfWeek.toISOString()];
  }

  public getDays(date: Date): Date[] {
    const dates = new Array<Date>();
    let selectedDate = dayjs(date);

    while (selectedDate.day() > DayOfWeek.Sunday) {
      selectedDate = selectedDate.subtract(1, 'days');
    }

    // Don't let dayjs variable modify itself
    const startDate = selectedDate.toDate();

    for (let i = 0; i < 7; i++) {
      dates.push(dayjs(startDate).add(i, 'days').toDate());
    }

    return dates;
  }

  public getDaysByRange(dateFrom: Date, dateTo: Date): Date[] {
    const dates = new Array<Date>();    
    const totalDays = dayjs(dateTo).diff(dateFrom, 'days');

    for (let i = 0; i <= totalDays; i++) {
      dates.push(dayjs(dateFrom).add(i, 'days').toDate());
    }

    return dates;
  }

  // Returns the total ms for the current project
  public getBilledHours(task: TimeEntry): number {
    const start = new Date(task.startTime);
    const end = !task.endTime ? new Date() : new Date(task.endTime);
    const ms = end.getTime() - start.getTime();
    const seconds = ms / 1000;
    return seconds / 3600;
  }

  public getMonthRange(): [Date, Date] {
    const dates = new Date(),
      y = dates.getFullYear(),
      m = dates.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    return [firstDay, lastDay];
  }

  public MergeTimeEntries(local: TimeEntry[], server: TimeEntry[]): TimeEntry[] {
    // current behavior: treat the server data as "always right" if it exists
    // i.e., throw out local records and replace them with the newest server records
    return server ? server : local;
  }

  public getServerTimeEntries(startDate: Date, endDate: Date): Observable<TimeEntry[]> {
    return this.http.post<TimeEntry[]>(`${this.projectUrl}/GetEntries`, {
      StartDate: startDate,
      EndDate: endDate
    });
  }

  public getServerTimeEntriesByCompany([startDate, endDate, companyId]: [Date, Date, UUID]): Observable<TimeEntry[]> {
    return this.http.post<TimeEntry[]>(`${this.projectUrl}/GetTeamEntries?companyId=${companyId}`, {
      StartDate: startDate,
      EndDate: endDate
    });
  }

  public getLocalDBTimeEntries(startDate: Date, endDate: Date, userId: UUID): Observable<TimeEntry[]> {
    return of(new Array<TimeEntry>());
  }

  public getLocalDBTimeEntriesByCompany([startDate, endDate, companyId]: [Date, Date, UUID]): Observable<TimeEntry[]> {
    return of(new Array<TimeEntry>());
  }


  public deleteTimeEntryLocally(entry: TimeEntry): Observable<TimeEntry> {
    return of(entry);
  }

  public upsertLocalDBTimeEntries(timeEntry: TimeEntry | TimeEntry[]): Observable<TimeEntry[] | TimeEntry> {
    return of([]);
  }

  public elapsedTime(startDate: Date): Observable<string> {
    return observableTimer(0, 500).pipe(map(e => this.getElapsedTime(startDate)));
  }

  public getElapsedTime(startDate: Date): string {
    const elapsedTime = dayjs().diff(startDate);
    const currentDuration = dayjs.duration(elapsedTime);
    let hours = currentDuration.hours().toString();
    let minutes = currentDuration.minutes().toString();
    let seconds = currentDuration.seconds().toString();
    if (hours.length < 2) {
      hours = '0' + hours;
    }

    if (minutes.length < 2) {
      minutes = '0' + minutes;
    }

    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }

    const elapsed = `${hours}:${minutes}:${seconds}`;
    return elapsed;
  }

  public showTimerDialog(): Observable<{}> {
    return new Observable(ob => {
      this.startTimerDiagRef = this.dialogService.open(StartTimerComponent, {
        panelClass: 'cartwheel-base-dialog',
        autoFocus: false,
        disableClose: true
      });
      ob.next('');
    });
  }

  public closeTimerDialog(): Observable<{}> {
    return new Observable(ob => {
      if (!this.startTimerDiagRef) {
        ob.error('There wasn\'t a dialog open');
      }
      this.startTimerDiagRef.close();
      ob.next('');
    });
  }

  public GiveMeFile(clientId: number, date: string) {
    const params = new URLSearchParams();
    params.append('dayInWeek', date);
    params.append('clientId', clientId.toString());
    return this.http.post(`${this.exportExcelURL}?${params.toString()}`, null, {
      responseType: 'blob'
    });
  }

  public GetReport(selectedDate: dayjs.Dayjs) {
    const urlHeaders = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
    const dayInWeek = 'dayInWeek=' + selectedDate.format();
    const postMe = `${this.projectUrl}/GetWeekReport?${dayInWeek}`;
    return this.http.post(postMe, null, { headers: urlHeaders });
  }

  public getTimeEntriesForCompany(dateRange: [Date, Date], companyId: UUID) {
    const urlHeaders = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
    const postMe = `${this.projectUrl}/GetCompanyEntries?companyId=${companyId}`;
    const data = new EntryRequest();
    data.startDate = dateRange[0];
    data.endDate = dateRange[1];
    return this.http.post(postMe, data);
  }

  // public initHub() {

  //   this.connection = new signalR.HubConnectionBuilder()
  //     // .configureLogging(signalR.LogLevel.Trace)
  //     .withUrl(`${environment.notificationServer}/timer/hub`, { accessTokenFactory: () => { return this.authService.getToken() } })
  //     .build();
  //   this.connection.start()
  //     .then(() => {
  //       console.log('connected');
  //     }).catch(err => {
  //       console.error(err.toString());
  //     });


  //   this.connection.on('EndTimerReceived', (entry: TimeEntry) => {
  //     this.store.dispatch(new EndTimerNoDispatchSuccessfulAction(entry));
  //   });

  //   this.connection.on('StartTimerReceived', (entry: RunningTimer) => {
  //     this.store.dispatch(new CheckIfTimerRunningSuccessfulAction(entry));
  //   });

  // }
}
