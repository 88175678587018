import dayjs from 'dayjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntryCreationLocation, TimeEntry } from '../../../models/timeentry';
import { StartTimerAction } from '../../../redux/actions/timer';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelSelectComponent, CartwheelSelectOptions, CartwheelTextareaComponent } from '@cartwheel/web-components';
import { Project } from '../../../models/project';
import { Observable } from 'rxjs';
import { Client } from '../../../models/Client';
import { Store } from '@ngrx/store';
import {
  getLoadedClients,
  getProjectsList, State,
  getSelectedClient, getProjectSelectedSingle, getProjectCompleted
} from '../../../redux/reducers/index';
import { Component, DestroyRef, HostListener, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { OpenClientAddDialogAction, SelectClientAction } from '../../../redux/actions/client';
import { MatDialogRef } from '@angular/material/dialog';
import { AddNewProject, SelectProjectAction } from '../../../redux/actions/project';
import { getProjectLoading, getRunningTimeEntry } from '../../../redux/reducers';
import { Status, SuccessStatus, ClientStatus, EventCategory } from '../../../shared/enums';
import { IntegrationService } from 'app/services/integration.service';
import { PipesModule } from 'app/pipes/pipes.module';

@Component({
  standalone: true,
  selector: 'app-start-timer',
  templateUrl: './start-timer.component.html',
  styleUrls: ['./start-timer.component.scss'],
  imports: [CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelSelectComponent, CartwheelTextareaComponent, ReactiveFormsModule, PipesModule]
})

export class StartTimerComponent implements OnInit {
  clients$: Observable<Client[]>;
  projects$: Observable<Project[]>;
  projectSaveComplete$: Observable<boolean>;
  projectsLoading$: Observable<boolean | SuccessStatus>;
  selectedClient$: Observable<Client>;
  selectedEntry$: Observable<TimeEntry>;
  selectedProject$: Observable<Project>;

  clients: Client[];
  currentEntry: TimeEntry;
  filteredProjects: Project[] = [];
  loadingProject: boolean | SuccessStatus;
  selectedClient: Client;
  selectedProject: Project;
  submitTimeEntry: UntypedFormGroup;
  taskDescription: string;
  unfilteredProjects: Project[] = [];
  public clientsOptions: CartwheelSelectOptions = [];
  public filteredProjectOptions: CartwheelSelectOptions = [];  
  public successStatus = SuccessStatus;

  constructor(public dialog: MatDialogRef<StartTimerComponent>,
    private destroyRef: DestroyRef,
    private integService: IntegrationService, private fb: UntypedFormBuilder,
    private store: Store<State>) { }

  ngOnInit() {
    this.clients$ = this.store.select(getLoadedClients);
    this.projects$ = this.store.select(getProjectsList);
    this.projectSaveComplete$ = this.store.select(getProjectCompleted);
    this.projectsLoading$ = this.store.select(getProjectLoading);
    this.selectedProject = new Project();
    this.selectedProject$ = this.store.select(getProjectSelectedSingle);
    this.selectedEntry$ = this.store.select(getRunningTimeEntry);
    this.selectedClient$ = this.store.select(getSelectedClient);

    this.selectedEntry$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(entry => {
      if (entry) {
        this.currentEntry = entry;
        if (this.currentEntry.clientId && this.currentEntry.projectId) {
          this.selectedProject = this.getProject(entry); // Prevents from the project id being = 0000-0000
        }
      }
    });
    this.selectedProject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(proj => {
      if (proj) {
        this.selectedProject = proj;
      } else {
        this.selectedProject = new Project();
      }
    });
    this.projectsLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(loading => this.loadingProject = loading);

    this.clients$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(clients => {
      this.clients = clients.filter(c => c.status !== ClientStatus.Deleted && c.status !== ClientStatus.Archived);
      this.clientsOptions = this.clients.map(c => ({
        label: c.clientName,
        value: c.clientID
      }));
    });

    this.projects$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projs => {
      this.unfilteredProjects = projs;
      if (this.selectedClient) {
        this.filteredProjects = projs.filter(proj => {
          return proj.clientId === this.selectedClient.clientID;
        });
        this.filteredProjectOptions = this.filteredProjects.map(p => ({
          label: p.projectName,
          value: p.projectId
        }));
      }
    });
    this.selectedClient$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(client => {
      if (client) {
        this.selectedClient = client;
        this.filteredProjects = this.unfilteredProjects.filter(proj => proj.clientId === this.selectedClient.clientID);
        this.filteredProjectOptions = this.filteredProjects.map(p => ({
          label: p.projectName,
          value: p.projectId
        }));        
        if (this.submitTimeEntry) {
          this.submitTimeEntry.get('selectedClient').setValue(this.selectedClient);
        }
      }
    });
    this.submitTimeEntry = this.fb.group({
      'selectedProject': [this.selectedProject, [Validators.required]],
      'selectedClient': [this.selectedClient, [Validators.required]],
      'taskDesc': [this.taskDescription, [Validators.required]]
    });

    // this.submitTimeEntry.get('selectedClient').valueChanges
    //   .pipe(takeUntilDestroyed(this.destroyRef))
    //   .subscribe(client => {
    //     this.selectClient(client);
    //   });
    // this.submitTimeEntry.get('selectedProject').valueChanges
    //   .pipe(takeUntilDestroyed(this.destroyRef))
    //   .subscribe(proj => {
    //     this.selectProject(proj);
    //   });
    this.submitTimeEntry.get('taskDesc').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(desc => (this.taskDescription = desc));
  }

  @HostListener('keydown.esc')
  onEsc(): void {
    this.dialog.close();
  }
  closeDialog() {
    this.dialog.close();
    this.integService.trackEvent('Timer', {
      category: EventCategory.Timer,
      message: 'Closed Timer dialog'
    });
  }
  addNewProject(projectName) {
    const existingProject = this.unfilteredProjects.find(s => s.projectName === projectName);
    if (existingProject) {
      this.selectProject(existingProject.projectId);
      return;
    }
    const newProject = new Project();
    newProject.projectId = UUID.UUID();
    newProject.clientId = this.selectedClient.clientID;
    newProject.projectName = projectName;
    this.integService.trackEvent('Timer', {
      category: EventCategory.Timer,
      message: 'Adding project from timer'
    });
    this.store.dispatch(new AddNewProject(newProject));
  }
  onSubmit(event) {
    if (this.submitTimeEntry.valid && this.selectedProject.projectName && this.selectedProject.projectName.length > 0) {
      const timer = new TimeEntry();
      timer.timeEntryId = UUID.UUID();
      timer.startTime = dayjs().toDate();
      timer.projectId = this.selectedProject.projectId;
      timer.clientId = this.selectedClient.clientID;
      timer.description = this.taskDescription;
      timer.status = Status.Active;
      timer.creationLocation = EntryCreationLocation.Timer;
      this.integService.trackEvent('Timer', {
        category: EventCategory[EventCategory.Timer],
        message: 'started timer',
        id: timer.timeEntryId
      });
      this.store.dispatch(new StartTimerAction([timer, this.selectedClient]));
      this.dialog.close();
    }
  }
  addClient() {
    this.integService.trackEvent('Timer', {
      category: EventCategory[EventCategory.Timer],
      message: 'Opened Add Client dialog',
    });
    return this.store.dispatch(new OpenClientAddDialogAction())
  }
  selectClient(clientID: string) {
    const client = this.clients.find(c => c.clientID === clientID);
    this.integService.trackEvent('Timer', {
      category: EventCategory[EventCategory.Timer],
      message: `Selected Client ${client.clientName}`,
      id: client.clientID
    });
    this.store.dispatch(new SelectProjectAction(undefined));
    return this.store.dispatch(new SelectClientAction(client));
  }
  selectProject(projectId: string) {
    const project = this.filteredProjects.find(p => p.projectId === projectId);
    if (project && project.clientId.length > 0) {
      this.integService.trackEvent('Timer', {
        category: EventCategory[EventCategory.Timer],
        message: `Selected Project ${project.projectName}`,
        id: project.projectId
      });
      this.store.dispatch(new SelectProjectAction(project))
    }
  }
  getProject(selectedEntry: TimeEntry): Project {
    if (selectedEntry && selectedEntry.clientId.length > 0) {
      return this.unfilteredProjects.find(s => s.projectId === selectedEntry.projectId);
    }
  }
}
