<div class="dialog-body">
  <div class="dialog-header">
    <span class="dialog-header-text">What are you working on?</span>
    @if (!loadingProject) {
      <cartwheel-icon-button matIconName="clear" class="dialog-close-btn" (onClick)="closeDialog()" />
    }
  </div>
  <div class="dialog-content px-15 tablet:px-75">
    <form class="grid grid-cols-1 gap-20 py-28" [formGroup]="submitTimeEntry" (ngSubmit)="onSubmit($event)">
      <cartwheel-select
        label="CLIENT NAME (REQUIRED)"
        addNewType="dialog"
        [options]="clientsOptions"
        (onClickAddNew)="addClient()"
        [value]="selectedClient?.clientID"
        (valueChange)="selectClient($event)"
      />

      <cartwheel-select
        label="Project"
        addNewType="input"
        [options]="filteredProjectOptions"
        [disabled]="!selectedClient"
        [addNewStatus]="
          (loadingProject === successStatus.Success && successStatus.Success) ||
          (loadingProject === successStatus.Error && successStatus.Error) ||
          ((loadingProject === true || loadingProject === successStatus.IsLoading) && successStatus.IsLoading)
        "
        (saveNewElement)="addNewProject($event)"
        [value]="selectedProject.projectId"
        (valueChange)="selectProject($event)"
      />

      <div>
        <cartwheel-textarea label="NOTES" [textareaFormControl]="submitTimeEntry.get('taskDesc') | formControlType" />
        @if (
          submitTimeEntry.controls.taskDesc &&
          submitTimeEntry.controls.taskDesc.status === 'INVALID' &&
          submitTimeEntry.controls.taskDesc.errors.required &&
          (submitTimeEntry.controls.taskDesc.touched || submitTimeEntry.controls.taskDesc.dirty)
        ) {
          <span class="text-danger text-12">Please enter notes.</span>
        }
      </div>

      <div class="flex items-center gap-5">
        <cartwheel-button
          class="[&>button]:px-40"
          label="Save"
          color="secondary"
          submitButton
          statusButton
          [status]="
            (!selectedProject.projectId || !selectedClient || !taskDescription || taskDescription === '') &&
            successStatus.Disabled
          "
        />
        @if (!loadingProject) {
          <cartwheel-button label="Cancel" type="text-button" color="middleGray" (onClick)="closeDialog()" />
        }
      </div>
    </form>
  </div>
</div>
